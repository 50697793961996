<template>
  <v-card flat v-show="bgLoaded || $vuetify.breakpoint.mobile">

    <v-container fluid fill-height class="pa-0" style="max-width: 100% !important">
      <v-row :class="'card-bg-step-00'" v-if="!$vuetify.breakpoint.mobile">
        <v-col cols="12" class="pb-0 d-inline-flex justify-center align-center flex-column">
          <v-slide-y-transition appear>
            <v-img @load="bgLoaded=true" eager class="d-block separator-size" src="assets/separator-blue-start.svg" style="margin-left: 12px !important" />
          </v-slide-y-transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          :class="
          [
            $vuetify.breakpoint.mobile ? 'pt-12' : 'pt-0'
          ]">
          <base-section
            step="00" 
            without-polygon
            title="LA PRIMERA VENTA DE UN INMUEBLE 100% MEDIANTE BLOCKCHAIN EN LA ARGENTINA"
            sub-title="Investigamos la Tokenización de activos y su venta utilizando tecnología Blockchain y su aplicación en el marco de la normativa argentina." 
            with-logo/>
        </v-col>
      </v-row>
    </v-container>
    
    <v-slide-y-transition appear>
      <header-component v-show="delayed"/>
    </v-slide-y-transition>

    <v-slide-y-transition appear>
      <conocenos-component v-show="delayed" @toSubasta="goToAccordion" />
    </v-slide-y-transition>

    <v-slide-y-transition appear>
      <accordion-home ref="accordion" />
    </v-slide-y-transition>

    <v-slide-y-transition appear>
      <n-f-t-section v-show="delayed" />
    </v-slide-y-transition>

    <v-slide-y-transition appear>
      <tecnologia v-show="delayed" />
    </v-slide-y-transition>

  </v-card>
</template>

<script>
export default {
  name: 'Home',
  components: {
    HeaderComponent: () => import('@/views/Home/Header.vue'),
    AccordionHome: () => import('@/views/Home/AccordionHome'),
    NFTSection: () => import('@/views/Home/NFTSection'),
    ConocenosComponent: () => import('@/views/Home/Conocenos'),
    Tecnologia: () => import('@/views/Home/Tecnologia'),
    BaseSection: () => import('@/views/components/BaseSection.vue')
  },
  data() {
    return {
      bgLoaded: false,
      delayed: false
      
    }
  },
  methods: {
    goToAccordion() {
      this.$vuetify.goTo(this.$refs.accordion, {duration: 300, offset: 0, easing: 'easeInOutCubic'})
    },
    baseLoaded() {
      this.baseIsLoaded
    }
  },
  mounted() {
    setTimeout(() => this.delayed = true, 350)
  }
}
</script>

<style>

</style>